export const UPDATE_USER_LAT_LONG = 'UPDATE_USER_LAT_LONG'

export const ERROR_REPORTED = 'ERROR_REPORTED'

// System actions
export const SET_EXPERIMENT = 'SET_EXPERIMENT'
export const UPDATE_APPLE_PAY_AVAILABLE = 'UPDATE_APPLE_PAY_AVAILABLE'
export const UPDATE_GOOGLE_PAY_AVAILABLE = 'UPDATE_GOOGLE_PAY_AVAILABLE'
export const DECLINE_NEWSLETTER_SUBSCRIPTION = 'DECLINE_NEWSLETTER_SUBSCRIPTION'
export const TOGGLE_SHOW_MARGIN_IN_SPOOF_MODE = 'TOGGLE_SHOW_MARGIN_SPOOF_MODE'
export const DISMISS_LUX_PLUS_FREE_PREVIEW_BANNER = 'DISMISS_LUX_PLUS_FREE_PREVIEW_BANNER'

// Optimizely actions
export const SET_OPTIMIZELY_EXPERIMENT = 'SET_OPTIMIZELY_EXPERIMENT'
export const SET_OPTIMIZELY_FEATURE_FLAG = 'SET_OPTIMIZELY_FEATURE_FLAG'

// Utm actions
export const SET_UTM = 'SET_UTM'

// Auth actions
export const ACCOUNT_INIT_COMPLETE = 'ACCOUNT_INIT_COMPLETE'
export const ACCOUNT_ACCESS_SHOW_LOGIN = 'ACCOUNT_ACCESS_SHOW_LOGIN'
export const ACCOUNT_ACCESS_SHOW_EMAIL_JOIN = 'ACCOUNT_ACCESS_SHOW_EMAIL_JOIN'
export const ACCOUNT_ACCESS_SHOW_REGISTER = 'ACCOUNT_ACCESS_SHOW_REGISTER'
export const ACCOUNT_ACCESS_SHOW_FORGOT_PASSWORD = 'ACCOUNT_ACCESS_SHOW_FORGOT_PASSWORD'
export const ACCOUNT_ACCESS_SET_MODE = 'ACCOUNT_ACCESS_SET_MODE'
export const SET_RECENTLY_USED_AIRPORT_CODE = 'SET_RECENTLY_USED_AIRPORT_CODE'
export const SAVE_CUSTOMER_SIGNATURE = 'SAVE_CUSTOMER_SIGNATURE'
export const DELETE_CUSTOMER_SIGNATURE = 'DELETE_CUSTOMER_SIGNATURE'
export const SYNC_USER_DETAILS = 'SYNC_USER_DETAILS'
export const GSI_SCRIPT_LOADED = 'GSI_SCRIPT_LOADED'
export const SET_CUSTOMER_VALUE = 'SET_CUSTOMER_VALUE'
export const CLEAR_AUTH_ERROR = 'CLEAR_AUTH_ERROR'

// Checkout experience actions
export const CHECKOUT_UPDATE_EXPERIENCE_ITEM = 'CHECKOUT_UPDATE_EXPERIENCE_ITEM'

// Insurance actions
export const RESET_INSURANCE = 'RESET_INSURANCE'
export const UPDATE_INSURANCE_PAYMENT_ITEM = 'UPDATE_INSURANCE_PAYMENT_ITEM'

// Order actions
export const UPDATE_CURRENT_ORDER = 'UPDATE_CURRENT_ORDER'
export const ADD_ORDERS = 'ADD_ORDERS'

// Offer actions
export const OFFERS_CLEAR = 'OFFERS_CLEAR'
export const OFFER_CALENDAR_RECOMMENDATIONS_CLEAR = 'OFFER_CALENDAR_RECOMMENDATIONS_CLEAR'
export const OFFER_SHARE_START = 'OFFER_SHARE_START'
export const OFFER_SHARE_COMPLETE = 'OFFER_SHARE_COMPLETE'
export const OFFER_SET_SEARCH_RESULT_METADATA = 'OFFER_SET_SEARCH_RESULT_METADATA'
export const OFFER_VIEWED = 'OFFER_VIEWED'
export const OFFER_UPDATE_PRICING_DATA = 'OFFER_UPDATE_PRICING_DATA'
export const BEDBANK_UPDATE_PRICING_DATA = 'BEDBANK_UPDATE_PRICING_DATA'
export const OFFER_UPDATE_AVAILABLE_RATES = 'OFFER_UPDATE_AVAILABLE_RATES'
export const OFFER_PUSH_TO_LIST_BOTTOM = 'OFFER_PUSH_TO_LIST_BOTTOM'

// Bnbl modal actions
export const UPDATE_GIFTING_BNBL_MODAL = 'UPDATE_GIFTING_BNBL_MODAL'
export const INITIALISE_BNBL_MODAL = 'INITIALISE_BNBL_MODAL'
export const UPDATE_BNBL_NUMBER_OF_ROOMS = 'UPDATE_BNBL_NUMBER_OF_ROOMS'
export const UPDATE_BNBL_PACKAGE_VIEW = 'UPDATE_BNBL_PACKAGE_VIEW'

// API based actions
export const API_CALL = 'API_CALL'
export const API_CALL_FAILURE = 'API_CALL_FAILURE'
export const API_CALL_REQUEST = 'API_CALL_REQUEST'
export const API_CALL_SUCCESS = 'API_CALL_SUCCESS'

// Flight based actions
export const CLEAR_FLIGHT_RESULT = 'CLEAR_FLIGHT_RESULT'
export const UPDATE_FLIGHT_FILTERS = 'UPDATE_FLIGHT_FILTERS'
export const UPDATE_FLIGHTS_SORT = 'UPDATE_FLIGHTS_SORT'
export const FETCH_AIRPORTS = 'FETCH_AIRPORTS'
export const FETCH_POPULAR_AIRPORTS = 'FETCH_POPULAR_AIRPORTS'
export const SET_FLIGHTS_CREDIT_DATA = 'SET_FLIGHTS_CREDIT_DATA'
export const FETCH_FLIGHT_CREDIT_RESERVATION = 'FETCH_FLIGHT_CREDIT_RESERVATION'
export const FETCH_FLIGHT_DEALS = 'FETCH_FLIGHT_DEALS'
export const FETCH_FLIGHT_DEAL = 'FETCH_FLIGHT_DEAL'

// Analytics based actions
export const PAGE_VIEW = 'PAGE_VIEW'
export const PRODUCT_CLICK_VIEW_DETAILS = 'PRODUCT_CLICK_VIEW_DETAILS'
export const MAP_PROPERTY_PIN_CLICK = 'MAP_PROPERTY_PIN_CLICK'
export const MAP_OFFER_INFO_CLICK = 'MAP_OFFER_INFO_CLICK'
export const MAP_PIN_OFFER_VIEW = 'MAP_PIN_OFFER_VIEW'
export const DEAL_VIDEO_PLAYED = 'DEAL_VIDEO_PLAYED'
export const DEAL_VIDEO_PAUSED = 'DEAL_VIDEO_PAUSED'
export const OFFER_PRODUCT_DETAIL_VIEW = 'OFFER_PRODUCT_DETAIL_VIEW'
export const PACKAGE_VIEW_MORE_DETAILS_CLICK = 'PACKAGE_VIEW_MORE_DETAILS_CLICK'
export const INITIALISE_SEARCH_SESSION_ID = 'INITIALISE_SEARCH_SESSION_ID'
export const SET_LERE_VERSION = 'SET_LERE_VERSION'
export const SET_OFFER_CHECK_IN_CHECK_OUT = 'SET_OFFER_CHECK_IN_CHECK_OUT'
export const SET_OFFER_LERE_INLINE_YMAL_IN_VIEW = 'SET_OFFER_LERE_INLINE_YMAL_IN_VIEW'
export const CLEAR_OFFER_CHECK_IN_CHECK_OUT = 'CLEAR_OFFER_CHECK_IN_CHECK_OUT'

// Calendar based actions
export const CLEAR_BOOKING_CALENDAR = 'CLEAR_BOOKING_CALENDAR'
export const UPDATE_CALENDAR_FLIGHT_PROVIDER = 'UPDATE_CALENDAR_FLIGHT_PROVIDER'
export const CALENDAR_UPDATE_PRICING_DATA = 'CALENDAR_UPDATE_PRICING_DATA'

// UI based actions
export const MODAL_OPEN = 'MODAL_OPEN'
export const BOOKING_DATE_WARNING_MODAL_OPEN = 'BOOKING_DATE_WARNING_MODAL_OPEN'
export const HOTEL_PRICE_CHANGE_MODAL_OPEN = 'HOTEL_PRICE_CHANGE_MODAL_OPEN'
export const BUNDLE_PRICE_CHANGE_MODAL_OPEN = 'BUNDLE_PRICE_CHANGE_MODAL_OPEN'
export const BEDBANK_HOTEL_PRICE_CHANGE_MODAL_OPEN = 'BEDBANK_HOTEL_PRICE_CHANGE_MODAL_OPEN'
export const TOUR_V2_PRICE_CHANGE_MODAL_OPEN = 'TOUR_V2_PRICE_CHANGE_MODAL_OPEN'
export const JOURNEY_PRICE_CHANGE_MODAL_OPEN = 'JOURNEY_PRICE_CHANGE_MODAL_OPEN'
export const JOURNEY_SOLD_OUT_MODAL_OPEN = 'JOURNEY_SOLD_OUT_MODAL_OPEN'
export const CANT_PROCESS_ORDER_ONLINE_MODAL_OPEN = 'CANT_PROCESS_ORDER_ONLINE_MODAL_OPEN'
export const FLIGHTS_SESSION_EXPIRED_MODAL_OPEN = 'FLIGHTS_SESSION_EXPIRED_MODAL_OPEN'
export const GENERIC_ORDER_ERROR_MODAL_OPEN = 'GENERIC_ORDER_ERROR_MODAL_OPEN'
export const AUTH_MODAL_OPEN = 'AUTH_MODAL_OPEN'
export const QUOTE_EMAIL_MODAL_OPEN = 'QUOTE_EMAIL_MODAL_OPEN'
export const CART_LINK_MODAL_OPEN = 'CART_LINK_MODAL_OPEN'
export const CONFIRMATION_EMAIL_MODAL_OPEN = 'CONFIRMATION_EMAIL_MODAL_OPEN'
export const MODAL_CLOSE = 'MODAL_CLOSE'
export const ACCOUNT_ACCESS_SHOW_PARTNERSHIP_LINK = 'ACCOUNT_ACCESS_SHOW_PARTNERSHIP_LINK'
export const SHOW_BRIDGER_PAY_PAYMENT_MODAL = 'SHOW_BRIDGER_PAY_PAYMENT_MODAL'
export const ADD_PAYMENT_PROVIDER = 'ADD_PAYMENT_PROVIDER'
export const SHOW_BRIDGERPAY_PAYMENT_FAILED_MODAL = 'SHOW_BRIDGERPAY_PAYMENT_FAILED_MODAL'
export const REGION_DROPDOWN_TOGGLE = 'REGION_DROPDOWN_TOGGLE'
export const SHOW_CUSTOMER_SIGNATURE_MODAL = 'SHOW_CUSTOMER_SIGNATURE_MODAL'
export const SHOW_CHECKOUT_CART_MODE_MODAL = 'SHOW_CHECKOUT_CART_MODE_MODAL'
export const SHOW_PAY_TO_AUTHORISATION_MODAL = 'SHOW_PAY_TO_AUTHORISATION_MODAL'
export const BEDBANK_SOLD_OUT_MODAL_OPEN = 'BEDBANK_SOLD_OUT_MODAL_OPEN'
export const SHOW_EXPERIENCE_CANCELLATION_MODAL = 'SHOW_EXPERIENCE_CANCELLATION_MODAL'

// Temp url params based actions
export const SET_PREFILL_QFF_NUMBER = 'SET_PREFILL_QFF_NUMBER'

// Content actions
export const DISMISS_NOTIFICATION_BANNER = 'DISMISS_NOTIFICATION_BANNER'

// BedbankOffer actions
export const BEDBANK_OFFER_PRODUCT_DETAIL_VIEW = 'BEDBANK_OFFER_PRODUCT_DETAIL_VIEW'
export const TOUR_V2_OFFER_PRODUCT_DETAIL_VIEW = 'TOUR_V2_OFFER_PRODUCT_DETAIL_VIEW'
export const TOUR_V2_OFFER_VARIATION_VIEW = 'TOUR_V2_OFFER_VARIATION_VIEW'

// Cruise actions
export const SAVE_CABIN_OPTION = 'SAVE_CABIN_OPTION'
export const RESET_REVELEX_CALLS = 'RESET_REVELEX_CALLS'
export const RESET_CABIN_AVAILABILITY = 'RESET_CABIN_AVAILABILITY'
export const CRUISE_IN_FLOW_CHECKOUT_STEP = 'CRUISE_IN_FLOW_CHECKOUT_STEP'
export const CRUISE_SET_SEARCH_FACETS = 'CRUISE_SET_SEARCH_FACETS'
export const CRUISE_SET_CRUISE_LINES = 'CRUISE_SET_CRUISE_LINES'
export const RESET_BOOKING_CABIN_SELECTION = 'RESET_BOOKING_CABIN_SELECTION'
export const SELECT_BOOKING_CRUISE_ITEM = 'SELECT_BOOKING_CRUISE_ITEM'
export const SET_BOOKING_INTEREST_IN_CONNECTED_CABINS = 'SET_BOOKING_INTEREST_IN_CONNECTED_CABINS'

// Checkout actions
export const CHECKOUT_INITIALISE = 'CHECKOUT_INITIALISE'
export const CHECKOUT_RESTORE_CART = 'CHECKOUT_RESTORE_CART'
export const CHECKOUT_REMOVE_TRAVELLER_SCHEMA = 'CHECKOUT_REMOVE_TRAVELLER_SCHEMA'
export const CHECKOUT_RESTORE_FORM = 'CHECKOUT_RESTORE_FORM'
export const CHECKOUT_RESET_FORM = 'CHECKOUT_RESET_FORM'
export const CHECKOUT_RESTORE_PAYMENT = 'CHECKOUT_RESTORE_PAYMENT'
export const CHECKOUT_UPDATE_ITEM = 'CHECKOUT_UPDATE_ITEM'
export const CHECKOUT_ADD_ITEM = 'CHECKOUT_ADD_ITEM'
export const CHECKOUT_REMOVE_ITEM = 'CHECKOUT_REMOVE_ITEM'
export const CHECKOUT_SET_FORM = 'CHECKOUT_SET_FORM'
export const CHECKOUT_EDIT_FORM = 'CHECKOUT_EDIT_FORM'
export const CHECKOUT_PROCESS_START = 'CHECKOUT_PROCESS_START'
export const CHECKOUT_PROCESS_CANCEL = 'CHECKOUT_PROCESS_CANCEL'
export const CHECKOUT_PROCESS_SUCCESS = 'CHECKOUT_PROCESS_SUCCESS'
export const CHECKOUT_PROCESS_FAILURE = 'CHECKOUT_PROCESS_FAILURE'
export const CHECKOUT_UPDATE_PAYMENT_SELECTION = 'CHECKOUT_UPDATE_PAYMENT_SELECTION'
export const CHECKOUT_SET_INSURANCE_FETCH_PARAMETERS = 'CHECKOUT_SET_INSURANCE_FETCH_PARAMETERS'
export const CHECKOUT_SET_LUXPLUS_UPSELL_DATA = 'CHECKOUT_SET_LUXPLUS_UPSELL_DATA'
export const CHECKOUT_FETCHING_PROMO_CODE = 'CHECKOUT_FETCHING_PROMO_CODE'
export const CHECKOUT_APPLY_PROMO_CODE = 'CHECKOUT_APPLY_PROMO_CODE'
export const CHECKOUT_APPLY_PROMO_CODE_FAILED = 'CHECKOUT_APPLY_PROMO_CODE_FAILED'
export const CHECKOUT_RESET_PROMO_CODE = 'CHECKOUT_RESET_PROMO_CODE'
export const CHECKOUT_RESET_FLIGHT_ITEMS = 'CHECKOUT_RESET_FLIGHT_ITEMS'
export const CHECKOUT_UPDATE_FLIGHT_TOTAL_FARE = 'CHECKOUT_UPDATE_FLIGHT_TOTAL_FARE'
export const CHECKOUT_CLEAR_CART_RESTORE_PRICE = 'CHECKOUT_CLEAR_CART_RESTORE_PRICE'
// These are just alternate versions of the Qantas actions above, for the new checkout state
export const CHECKOUT_APPLY_VELOCITY_BURN = 'CHECKOUT_APPLY_VELOCITY_BURN'
export const CHECKOUT_CLEAR_VELOCITY_BURN = 'CHECKOUT_CLEAR_VELOCITY_BURN'
export const CHECKOUT_SET_VELOCITY_BURN_MODAL_STATUS = 'CHECKOUT_SET_VELOCITY_BURN_MODAL_STATUS'
export const CHECKOUT_APPLY_PARTNERSHIP_STATUS = 'CHECKOUT_APPLY_PARTNERSHIP_STATUS'
export const CHECKOUT_SET_SPECIAL_REQUEST = 'CHECKOUT_SET_SPECIAL_REQUEST'
export const CHECKOUT_SET_CONNECT_ROOMS_SPECIAL_REQUEST = 'CHECKOUT_SET_CONNECT_ROOMS_SPECIAL_REQUEST'
export const CHECKOUT_SET_ROOMS_TO_BE_CONNECTED = 'CHECKOUT_SET_ROOMS_TO_BE_CONNECTED'
export const CHECKOUT_TOGGLE_CREDIT = 'CHECKOUT_TOGGLE_CREDIT'
export const CHECKOUT_UPDATE_FLIGHT_ITEM_PAX_BAGGAGE = 'CHECKOUT_UPDATE_FLIGHT_ITEM_PAX_BAGGAGE'
export const CHECKOUT_SHOW_PROMO_CORPORATE_BENEFITS = 'CHECKOUT_SHOW_PROMO_CORPORATE_BENEFITS'
export const CHECKOUT_HIDE_PROMO_CORPORATE_BENEFITS = 'CHECKOUT_HIDE_PROMO_CORPORATE_BENEFITS'
export const CHECKOUT_SHOW_PROMO_FRIENDS_AND_FAMILY = 'CHECKOUT_SHOW_PROMO_FRIENDS_AND_FAMILY'
export const CHECKOUT_HIDE_PROMO_FRIENDS_AND_FAMILY = 'CHECKOUT_HIDE_PROMO_FRIENDS_AND_FAMILY'
export const CHECKOUT_SET_RESTORE_CART_STATUS = 'CHECKOUT_SET_RESTORE_CART_STATUS'
export const CHECKOUT_MODIFY_FLIGHT_SEARCH_VIEW = 'CHECKOUT_MODIFY_FLIGHT_SEARCH_VIEW'
export const CHECKOUT_SET_POST_PURCHASE = 'CHECKOUT_SET_POST_PURCHASE'
export const CHECKOUT_UPDATE_HOTEL_ITEM_PRICING = 'CHECKOUT_UPDATE_HOTEL_ITEM_PRICING'
export const CHECKOUT_UPDATE_BUNDLE_ITEM_PRICING = 'CHECKOUT_UPDATE_BUNDLE_ITEM_PRICING'
export const CHECKOUT_TOGGLE_DEV_TOOLS = 'CHECKOUT_TOGGLE_DEV_TOOLS'
export const CHECKOUT_CLEAR_CART = 'CHECKOUT_CLEAR_CART'
export const CHECKOUT_UPDATE_ARRIVAL_FLIGHT_NUMBER = 'UPDATE_ARRIVAL_FLIGHT_NUMBER'
export const CHECKOUT_AGENT_BOOKING_DETAILS = 'CHECKOUT_AGENT_BOOKING_DETAILS'
export const CHECKOUT_BUSINESS_BOOKING_DETAILS = 'CHECKOUT_BUSINESS_BOOKING_DETAILS'
export const CHECKOUT_SELECT_NO_INSURANCE = 'CHECKOUT_SELECT_NO_INSURANCE'
export const CHECKOUT_SELECT_NO_TRAVEL_PROTECTION = 'CHECKOUT_SELECT_NO_TRAVEL_PROTECTION'
export const CHECKOUT_SET_MULTI_CART_ITEM_MODE = 'CHECKOUT_SET_MULTI_CART_ITEM_MODE'
export const CHECKOUT_SET_MERCHANT_FEE_PAYMENT_TYPE = 'CHECKOUT_SET_MERCHANT_FEE_PAYMENT_TYPE'
export const CHECKOUT_SET_COMMS_RESUBSCRIBE = 'CHECKOUT_SET_COMMS_RESUBSCRIBE'
export const UPDATE_PAYMENT_SELECTION = 'UPDATE_PAYMENT_SELECTION'
export const ACTIVATE_REBOOKING = 'ACTIVATE_REBOOKING'
export const APPLY_PROMO_CODE = 'APPLY_PROMO_CODE'

// Genesys custom events actions
export const GENESYS_CUSTOM_EVENT = 'GENESYS_CUSTOM_EVENT'
export const GENESYS_SEARCH_EVENT = 'GENESYS_SEARCH_EVENT'
export const OPEN_CHAT_WINDOW = 'OPEN_CHAT_WINDOW'

// Experience Actions
export const EXPERIENCE_VIEWED = 'EXPERIENCE_VIEWED'
export const SET_EXPERIENCE_PLACE = 'SET_EXPERIENCE_PLACE'
export const CLEAR_EXPERIENCES_TIMES = 'CLEAR_EXPERIENCES_TIMES'
export const SET_CANCELLING_EXPERIENCES = 'SET_CANCELLING_EXPERIENCES'

// Navigation actions
export const PATH_CHANGE = 'PATH_CHANGE'

// Tour traveller details actions
export const TOUR_TRAVELLER_DETAILS_RESET_SUBMISSION = 'TOUR_TRAVELLER_DETAILS_RESET_SUBMISSION'

// User reviews
export const USER_REVIEW_SET_PHOTO_UPLOAD_PERCENTAGE = 'USER_REVIEW_SET_PHOTO_UPLOAD_PERCENTAGE'
export const USER_REVIEW_SET_REVIEW_STAGE = 'USER_REVIEW_SET_REVIEW_STAGE'

// Velocity Auth
export const CHECKOUT_SET_VELOCITY_SSO = 'CHECKOUT_SET_VELOCITY_SSO'

// Sticky prompt card
export const ENABLE_STICKY_PROMPT_CARD = 'ENABLE_STICKY_PROMPT_CARD'
export const DISABLE_STICKY_PROMPT_CARD = 'DISABLE_STICKY_PROMPT_CARD'

export const CHECKOUT_UPDATE_CS_DEPOSIT_OVERRIDE = 'CHECKOUT_UPDATE_CS_DEPOSIT_OVERRIDE'

// LERE actions
export const LERE_SAVE_HOT_LEADS = 'LERE_SAVE_HOT_LEADS'
export const LERE_REMOVE_HIGH_INTENT = 'LERE_REMOVE_HIGH_INTENT'
export const LERE_SAVE_ABANDONED_CART = 'LERE_SAVE_ABANDONED_CART'
export const LERE_DISMISS_ALL_STICKY_ABANDONED_CART = 'LERE_DISMISS_ALL_STICKY_ABANDONED_CART'
export const LERE_REMOVE_ABANDONED_CART = 'LERE_REMOVE_ABANDONED_CART'
export const LERE_SAVE_DEFAULT_RECOMMENDATION = 'LERE_SAVE_DEFAULT_RECOMMENDATION'
export const LERE_RESET_PERSONALISED_PREFERENCES = 'LERE_RESET_PERSONALISED_PREFERENCES'

// Loyalty actions
export const CLEAR_LOYALTY_MEMBER_SUBSCRIPTION = 'CLEAR_LOYALTY_MEMBER_SUBSCRIPTION'

// Agent hub
export const SET_CUSTOMER_VIEW_TOGGLE = 'SET_CUSTOMER_VIEW_TOGGLE'

export const CHECKOUT_SET_STRIPE_PAYMENT_METHOD = 'CHECKOUT_SET_STRIPE_PAYMENT_METHOD'

// SherLog actions
export const LOG_NETWORK_REQUEST = 'LOG_NETWORK_REQUEST'
export const LOG_NETWORK_RESPONSE = 'LOG_NETWORK_RESPONSE'

// Arrival Details
export const CHECKOUT_ADD_ARRIVAL_DETAILS = 'CHECKOUT_ADD_ARRIVAL_DETAILS'
export const CHECKOUT_SET_ARRIVAL_DETAILS_TIME = 'CHECKOUT_SET_ARRIVAL_DETAILS_TIME'
export const CHECKOUT_SET_ARRIVAL_DETAILS_FLIGHT_NUMBER = 'CHECKOUT_SET_ARRIVAL_DETAILS_FLIGHT_NUMBER'

// PayTo
export const CHECKOUT_SET_PAYTO_BANK = 'CHECKOUT_SET_PAYTO_BANK'

// Refund request actions
export const UPDATE_CURRENT_REFUND_REQUEST = 'UPDATE_CURRENT_REFUND_REQUEST'

// Support Assistant Actions
export const SET_CURRENT_CHAT_ID = 'SET_CURRENT_CHAT_ID'
export const ADD_DEFAULT_CHAT = 'ADD_DEFAULT_CHAT'
export const UPDATE_CHAT = 'UPDATE_CHAT'
export const UPDATE_CHAT_TITLE = 'UPDATE_CHAT_TITLE'
export const ADD_CHAT_MESSAGE = 'ADD_CHAT_MESSAGE'
export const SET_CHAT_OUTAGE_MESSAGE = 'SET_CHAT_OUTAGE_MESSAGE'
